'use client'

import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

/**
 * Client Component to maintain state (specifically the selected period) for logged-in users.
 *
 * This can be passed down to Server Components, which will be rendered on the server and returned
 * to the client in a fully rendered state.
 */
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { toI18nString } from '@roadmap/lib/i18n'
import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'
import {
  ChecklistItem,
  ContentRecord,
  GreatestHitsRecord,
  LiveEventsRecord,
  Locales,
  RoadmapDate,
  TaxonomyRecord,
  ToolRecord,
} from '@roadmap/lib/types/data'
import { getPeriodStringForDate } from '@roadmap/lib/utils/dates'

interface Props extends React.PropsWithChildren {
  initialPeriod: string | null
  locale?: string
  checklistItems?: ChecklistItem[] | null
  taxonomy?: TaxonomyRecord[] | null
}
interface ICurrentPeriodData {
  checklistItems?: ChecklistItem[]
  content?: ContentRecord[]
  dates?: RoadmapDate[]
  liveEvents?: LiveEventsRecord[]
  tools?: { [key: string]: ToolRecord[] }
  dyk?: { [key: string]: any }[]
  greatestHits?: GreatestHitsRecord[]
  counselorDevResources?: ContentRecord[]
  completedChecklistItems?: number | null | undefined
  completedChecklistPercent?: number | null | undefined
}

export interface ICurrentlyPlayingContent {
  type: string
  url: string
  title?: string
  description?: string
  additionalHeader?: JSX.Element
  experts?: { [key: string]: any }[]
  id?: string
}

interface IContentDataContext {
  currentPeriodData: ICurrentPeriodData
  setCurrentPeriodData: Dispatch<SetStateAction<ICurrentPeriodData>>
  currentContent: ContentRecord | null
  setCurrentContent: Dispatch<SetStateAction<ContentRecord | null>>
  completedChecklistItems: number | null
  completedChecklistPercent: number | null
  schoolLogoUrl: string | null
  setSchoolLogoUrl: Dispatch<SetStateAction<string | null>>
  taxonomy: string[]
}

export const ContentDataContext = createContext<IContentDataContext>({
  currentPeriodData: {},
  setCurrentPeriodData: () => {},
  currentContent: null,
  setCurrentContent: () => null,
  completedChecklistItems: null,
  completedChecklistPercent: null,
  schoolLogoUrl: null,
  setSchoolLogoUrl: () => null,
  taxonomy: [],
})

export const ContentDataProvider = (props: Props) => {
  const { locale = 'en', initialPeriod, checklistItems: initialChecklistItems, taxonomy, children } = props
  const { data: session, update, status } = useSession()
  const t = useTranslations('Content')
  const [periodBeingViewed, setPeriodBeingViewed] = useState<string | null>(
    session?.user.periodBeingViewed || initialPeriod
  )
  const [currentPeriodData, setCurrentPeriodData] = useState<ICurrentPeriodData>({
    checklistItems: initialChecklistItems || [],
  })
  const [currentContent, setCurrentContent] = useState<ContentRecord | null>(null)
  const [completedChecklistItems, setCompletedChecklistItems] = useState<number | null>(null)
  const [completedChecklistPercent, setCompletedChecklistPercent] = useState<number | null>(null)
  const [schoolLogoUrl, setSchoolLogoUrl] = useState<string | null>(null)
  const [currentTaxonomy, setCurrentTaxonomy] = useState<string[]>(
    taxonomy ? taxonomy.map((t) => toI18nString(t.name, locale as Locales)) : []
  )

  useMemo(() => {
    async function fetchDataForPeriod() {
      if (session?.user.role === UserRoles.COUNSELOR) {
        const response = await fetch(
          `/api/roadmap/data/counselor?${new URLSearchParams({ locale: locale }).toString()}`
        )
        const responseData = await response.json()
        setCurrentPeriodData(responseData)
      } else {
        const period = getPeriodStringForDate(session?.user.graduationYear || new Date().getFullYear() + 1)
        if (period) {
          const response = await fetch(
            `/api/roadmap/data/${period}?${new URLSearchParams({ locale: locale }).toString()}`
          )
          const responseData = await response.json()
          console.debug(`User selected pathways: `, session?.user.pathways)
          setCurrentPeriodData(responseData)
        }
      }
    }
    fetchDataForPeriod().catch((err) => {
      console.log('Error retrieving content data', err)
    })
  }, [session?.user.periodBeingViewed, session?.user.pathways, session?.user.role, locale])

  useEffect(() => {
    // On logout, clear the current data cache
    if (!['authenticated', 'loading'].includes(status)) {
      setCurrentPeriodData({})
    }
  }, [status])

  useEffect(() => {
    if (
      currentPeriodData.greatestHits &&
      currentPeriodData.greatestHits.length > 0 &&
      currentPeriodData.greatestHits[0].content &&
      currentPeriodData.greatestHits[0].content.length > 0
    ) {
      const contentRecord = currentPeriodData.greatestHits[0].content[0]
      if (contentRecord && contentRecord.format.includes('Video') && contentRecord.embedUrl) {
        setCurrentContent({
          ...contentRecord,
          additionalHeader: (
            <p className="my-4 py-4 text-left text-4xl font-bold">
              {t('editorsPick')} - {t('videoOfTheMonth')}
            </p>
          ),
        })
        return () => {
          setCurrentContent(null)
        }
      } else if (contentRecord && contentRecord.format.includes('PDF')) {
        const url = contentRecord.embedUrl || contentRecord.pdfUrl || contentRecord.URL
        if (url) {
          setCurrentContent({
            ...contentRecord,
            additionalHeader: (
              <p className="my-4 py-4 text-left text-4xl font-bold">
                {t('editorsPick')} - {t('contentOfTheMonth')}
              </p>
            ),
          })
          return () => {
            setCurrentContent(null)
          }
        }
      }
    } else {
      if (currentPeriodData.content && currentPeriodData.content.length > 0) {
        const contentRecord = currentPeriodData.content[0]
        if (contentRecord.format.includes('Video') && contentRecord.embedUrl) {
          setCurrentContent({
            ...contentRecord,
            additionalHeader: (
              <p className="my-4 py-4 text-left text-4xl font-bold">
                {t('editorsPick')} - {t('videoOfTheMonth')}
              </p>
            ),
          })
          return () => {
            setCurrentContent(null)
          }
        } else if (contentRecord.format.includes('PDF')) {
          const url = contentRecord.embedUrl || contentRecord.pdfUrl || contentRecord.url
          if (url) {
            setCurrentContent({
              ...contentRecord,
              additionalHeader: (
                <p className="my-4 py-4 text-left text-4xl font-bold">
                  {t('editorsPick')} - {t('videoOfTheMonth')}
                </p>
              ),
            })
            return () => {
              setCurrentContent(null)
            }
          }
        }
      }
    }
  }, [currentPeriodData, t])

  /**
   * Set currentContent for a counselor
   */
  useEffect(() => {
    if (currentPeriodData.counselorDevResources && currentPeriodData.counselorDevResources.length > 0) {
      const contentRecord = currentPeriodData.counselorDevResources[0]
      if (contentRecord && contentRecord.format.includes('Video') && contentRecord.embedUrl) {
        setCurrentContent(contentRecord)
        return () => {
          setCurrentContent(null)
        }
      } else if (contentRecord.format.includes('PDF')) {
        const url = contentRecord.embedUrl || contentRecord.pdfUrl || contentRecord.url
        if (url) {
          setCurrentContent(contentRecord)
          return () => {
            setCurrentContent(null)
          }
        }
      }
    }
  }, [currentPeriodData.counselorDevResources])

  useEffect(() => {
    const { checklistItems = [] } = currentPeriodData
    const completedChecklistItems = checklistItems.filter((item) => {
      return session?.user.completedChecklistItems?.includes(item.id)
    })
    const checklistItemsCompletedCount = completedChecklistItems.length
    const checklistProgress = checklistItems.length > 0 ? checklistItemsCompletedCount / checklistItems.length : 0
    setCompletedChecklistItems(checklistItemsCompletedCount)
    setCompletedChecklistPercent(checklistProgress)
  }, [session?.user.completedChecklistItems, currentPeriodData])

  /**
   * When a known user first logs in, they should have a graduationYear set in their session,
   * but they won't have a periodBeingViewed (since that's ephemeral).  This effect sets the
   * period based on the graduationYear
   */
  useEffect(() => {
    if (session?.user.graduationYear && !session.user.periodBeingViewed) {
      update({ periodBeingViewed: getPeriodStringForDate(session.user.graduationYear) })
    }
  }, [session?.user.graduationYear, session?.user.periodBeingViewed, update])

  /**
   * If the locale gets updated, update the taxonomy to reflect the new locale
   */
  useEffect(() => {
    if (taxonomy) {
      setCurrentTaxonomy(taxonomy.map((t) => toI18nString(t.name, locale as Locales)).sort())
    }
  }, [locale, taxonomy])

  return (
    <ContentDataContext.Provider
      value={{
        currentPeriodData,
        setCurrentPeriodData,
        currentContent,
        setCurrentContent,
        completedChecklistItems,
        completedChecklistPercent,
        schoolLogoUrl,
        setSchoolLogoUrl,
        taxonomy: currentTaxonomy,
      }}
    >
      {children}
    </ContentDataContext.Provider>
  )
}

const useContentData = () => {
  const value = useContext(ContentDataContext)
  return value
}

export { useContentData }
export default ContentDataContext
