export enum Collections {
  ACCOUNTS = 'accounts',
  AI_INTERACTIONS = 'aiInteractions',
  CHATBOT_FEEDBACK = 'chatbotFeedback',
  CHECKLIST_ITEMS = 'checklistItems',
  CONTENT = 'content',
  CONTENT_TYPES = 'contentTypes',
  CONTENT_UPDATES = 'contentUpdates',
  CONTENT_VIEWS = 'contentViews',
  DATES = 'roadmapDates',
  DID_YOU_KNOW = 'didYouKnow',
  EMAIL_VERIFICATIONS = 'emailVerifications',
  EXPERTS = 'experts',
  GREATEST_HITS = 'greatestHits',
  JOBS = 'jobs',
  JOB_LOGS = 'jobLogs',
  JOB_LOG_ENTRIES = 'jobLogEntries',
  LIVE_EVENTS = 'liveEvents',
  NUDGES = 'nudges',
  PASSWORD_RESETS = 'passwordResets',
  REQUESTS = 'requests',
  SCHOOLS = 'schools',
  SEARCHES = 'searches',
  SMS_MESSAGES = 'smsMessages',
  STARTER_PACKS = 'starterPacks',
  STARTER_PACK_TOOLS = 'starterPackTools',
  TAXONOMY = 'taxonomy',
  TOOLS = 'tools',
  TRUSTED_LINKS = 'trustedLinks',
  USERS = 'users',
  USER_SESSIONS = 'userSessions',
}

export const TimelinePlacementFields: { [key: string]: string } = {
  [Collections.CHECKLIST_ITEMS]: 'timelinePlacement',
  [Collections.DATES]: 'timelinePlacement',
  [Collections.DID_YOU_KNOW]: 'timelinePlacement',
  [Collections.GREATEST_HITS]: 'timelinePlacement',
  [Collections.CONTENT]: 'timelinePlacement',
}

// Defaults to assuming an array, unless redefined here
export const TimelinePlacementFieldTypes: { [key: string]: string } = {
  [Collections.DID_YOU_KNOW]: 'string',
  [Collections.GREATEST_HITS]: 'string',
}

export const PathwayFields: { [key: string]: string } = {
  [Collections.CHECKLIST_ITEMS]: 'pathways',
  [Collections.DATES]: 'attributesAndPathways',
  [Collections.DID_YOU_KNOW]: 'pathway',
  [Collections.CONTENT]: 'pathways',
}

export enum UserRoles {
  COUNSELOR = 'Counselor',
  STUDENT = 'Student',
  FAMILY = 'Family Member',
  STAFF = 'Faculty/Staff',
}
